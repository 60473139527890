<template>
  <div class="badge" v-bind:class="stateColor" v-text="state"></div>
</template>

<script>
  export default {
    name: "FileState",
    props: {
      state: {
        type: String,
        default: "",
      }
    },
    computed: {
      stateColor() {
        switch (this.state) {
          case 'UPLOADED':
          case 'PREVIEWED':
            return "badge-info";
          case 'ERROR_ON_READ':
          case 'ERROR_ON_CONVERT':
            return "badge-danger";
          case 'CORRUPTED':
            return 'badge-dark';
          case 'CONVERTED_WITH_MISSING':
            return "badge-warning";
          case 'CONVERTING':
            // no idea how to mark still converting files
            // return 'badge-warning';
            break;
          case 'CONVERTED':
            return "badge-success";
        }
        return "badge-primary";
      }
    }
  }
</script>

<style scoped>

</style>
