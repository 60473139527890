export const ee = {
  'value': 'EST',
  'idCode': 'Isikukood',
  'firstName': 'Eesnimi',
  'lastName': 'Perekonnanimi',
  'shortName': 'Lühinimi',
  'email': 'Email',
  'permissions': 'Õigused',
  'language': 'Keel',
  'converter': {
    'converter': 'Kodeerija',
    'headers': 'Päised',
    'code': 'Kood',
    'horizontal': 'Horisontaal',
    'vertical': 'Vertikaal',
    'checkbox': 'Linuke',
    'buttons': 'Nupp',
    'removeAll': 'Eemalda kõik',
    'addAll': 'Lisa kõik',
    'convertFile': 'Alusta kodeerimist',
    'keep': 'Lisa',
    'discard': 'Eemalda',
    'showFinal': 'Eelvaade',
    'convert': 'Kodeeri fail',
    'reconvert': 'Kodeeri algfail uuesti',
    'resultConvert': 'Kodeeri tulemus faili',
    'continueEditing': 'Tagasi muuda',
    'inProgress': 'Palun oodake',
    'previousConversion': 'Varasem kodeerimine | Varasemad kodeerimised',
    'previousUploads': 'Varasemad failid',
    'giveName': 'Anna nimi',
    'projectCode': 'Projekti kood',
    'columnSelect': 'Tulba valik',
    'personCodeGroup': 'SKOODi grupp',
    'selectPersonCodeGroup': 'Vali SKOODi grupp',
    'sampleCodeGroup': 'VKOODi grupp',
    'selectSampleCodeGroup': 'Vali VKOODi grupp',
    'hiddenColumns': 'Peidetud {count} tulp|Peidetud {count} tulpa',
    'btn-reset': 'Lähtesta',
    'btn-copyExcel': 'Copy excel',
    'btn-copyJson': 'Copy JSon',
    'btn-showHiddenColumns': 'Näita veel tulpi',
    'step': {
      '1': 'Vali kood',
      '2': 'Vali tulbad',
      '3': 'Ülevaatus',
    },
    'instruction': {
      '1': 'Leia tulp koodiga ja märgista see',
      '2': 'Märgi ebavajalikud tulbad vajutades tulba päiseid',
      '3': 'Veendu, et alles oleks vaid vajalikud tulbad',
    }
  },
  'nav': {
    'releases': 'Väljastamised',
    'home': 'Avaleht',
    'uploads-list': 'Failid',
    'conversions-list': 'Kodeerimised',
    'users': 'Kasutajad',
    'logout': 'Logi välja',
    'projects-list': 'Projektid',
    'code-usage': 'Koodide kasutus',
    'back': 'Tagasi',
  },
  'login': {
    'sign-in': 'Palun logi sisse',
    'message-sent': 'Sõnum saadetud',
    'check-code': 'Kontrollkood: ',
    'cancel': 'Katkesta',
    'smart-ID': 'Smart-ID',
    'smart-ID-button': 'Sisene Smart-ID`ga',
    'smart-ID-instructions': 'Sisesta oma telefonis Smart-ID PIN1, kui oled veendunud, telefonis kuvatav kontrollkood on sama kui siin.',
    'ID-card': 'Sisene ID kaart`ga',
    'mobile-ID': 'Mobiil-ID',
    'mobile-ID-button': 'Sisene Mobiil-ID`ga',
    'mobile-ID-instructions': 'Sisesta oma telefonis Mobiil-ID PIN1, kui oled veendunud, telefonis kuvatav kontrollkood on sama kui siin.',
    'phoneNo': 'Telefoni number',
    'login': 'Logi sisse'
  },
  'file': {
    'state': 'Staatus',
    'storageState': 'Talletus staatus',
    'name': 'Nimi',
    'size': 'Suurus',
    'lines': 'Read',
    'hash': 'Räsi | Räsi({hashAlgorithm})',
    'samples': 'Proovid',
    'people': 'Inimesed',
    'missing': 'Puudulikud',
    'previewMissing': 'Vaata puudulike koode',
    'original': 'Algfail',
    'complete': 'Tööfail',
    'release': 'Väljastus',
    'converted': 'Kodeeritud: ',
    'info': 'Info',
    'upload': 'Üleslaetud fail',
    'filter': {
      'title': 'Filtreerimine',
      'id': {
        'field': 'Faili id:',
        'placeholder': 'Faili id',
      },
      'name': {
        'field': 'Faili nimi:',
        'placeholder': 'Faili nimi',
      },
      'uploaders': {
        'field': 'Üleslaadijad:',
        'placeholder': 'Vali üleslaadijad',
      },
      'states': {
        'field': 'Staatused:',
        'placeholder': 'Vali staatused',
      },
      'projects': {
        'field': 'Projekti nimi:',
        'placeholder': 'Vali projekt',
      },
    },
    'uploadedFiles': 'Üleselaetud failid',
    'uploadedAt': 'Üleselaetud',
    'uploadedBy': 'Üleselaadija',
    'convertedAt': 'Kodeeritud',
    'convertedBy': 'Kodeerija',
    'lastModifiedAt': 'Viimati muudetud',
    'lastModifiedBy': 'Viimane muutja',
    'preview': 'Eelvaade',
    'download': 'Lae alla',
    'downloadName': 'Anna allalaetavle failile nimi',
    'noConversions': 'Varasemad kodeerimised puuduvad',
    'noProject': 'Pole seotud projektiga',
  },
  'user': {
    'create': 'Loo konto',
    'update': 'Uuenda konto',
    'isActive': 'Konto staatus:',
    'active': 'Aktiivne',
    'disabled': 'Suletud',
    'list': 'Kasutajate nimekiri',
  },
  'home': {
    'homePage': 'Väljastused rakenduse avaleht',
    'siteDescription': 'Võimalda ümber koodeerida .csv ja .txt faile vatstavate koodidega(vkood, ukood, skood, lkood)',
    'pageDescriptions': 'Käesoleva rakenduse lehed ja nende eesmärgid',
    'conversionsDescription': 'Lehe põhi funksionaalsus - siin saab üles laadida faile ja neid ümber kodeerida',
    'usersDescription': 'Kasutajate loomine ja nende õiguste haldamine',
    'eventHistory': 'Viimased tegemised'
  },
  'apiKey': {
    'title': 'API võti',
    'info': ' API võti on vajalik, et excel add-in saaks suhelda serveriga. Excel add-in\'i töötab O365 macOS ja Windows operatsioonisüsteemil.',
    'exists': 'Teil on juba olemasolev API võti.',
    'genWarn': 'Uue võtme loomine hävitab vana võtme - vajab rekonfiguratsiooni.',
    'genInfo': 'Hetkel Teil puudub API võti. Vajadusel loo võit Excel addin kasutamiseks.',
    'copy': 'Copy',
    'generate': 'Loo võti',
    'destroy': 'Hävita võti',
    'true': 'Olemas',
    'false': 'Puudub',
  },
  'codeRelations': {
    'title': 'Kooditabelite uuendamine',
    'info': 'Ehita uued koodiseoste tabeleid värskete anmete põhjal.',
    'update': 'Uuenda koodiseoseid',
    'updating': 'Uuendan koodide seoseid',
    'updateSuccess': 'Uued koodiseosed edukalt loodud',
  },
  'upload': {
    'new': 'Lae üles uus fail',
    'upload': 'Lae üles',
    'browse': 'Sirvi faile',
    'choose': 'Lisa fail',
    'progress': 'Üleslaadimisel - {progress}% - {estimated}s',
    'finished': 'Fail on ülese laetud',
  },
  'conversions': {
    'result': 'Kodeerimise tulemus',
    'filter': {
      'title': 'Kodeerimiste filtreerimine',
      'projects': {
        'field': 'Projekti kood:',
        'placeholder': 'Projekti kood',
      },
      'converter': {
        'field': 'Koodeerija:',
        'placeholder': 'Vali kasutaja',
      },
    },
  },
  'project': {
    'project': 'Projekt',
    'projects': 'Projektid',
    'name': 'Nimi',
    'code': 'Kood',
    'create': 'Loo projekt',
    'edit': 'Muuda projekti',
    'createdBy': 'Looja',
    'createdAt': 'Loodud',
    'lastModifiedBy': 'Viimane muutja',
    'lastModifiedAt': 'Viimati muudetud',
    'missing': 'Puudub',
    'attach': 'Lisa projektile',
    'quickSelect': 'Kiirvalik',
    'filter': {
      'title': 'Projekti filter',
      'projects': {
        'field': 'Projekt:',
        'placeholder': 'Projekti kood',
      },
      'name': {
        'field': 'Nime:',
        'placeholder': 'Projekti nime',
      },
      'creator': {
        'field': 'Looja:',
        'placeholder': 'Vali kasutaja',
      },
    },
    'form-name': 'Projekti nimi',
    'form-code': 'Projekti kood',
    'btn-create': 'Loo projekt',
  },
  'code-usage': {
    'usage': 'Kasutuse info',
    'lookup': 'Vaata koodi kasutust',
    'code': 'Kood',
    'type': 'Tüüp',
    'ukood': 'UKOOD',
    'lkood': 'LKOOD',
    'usageCount': 'Kodeerimite arv',
    'listView': 'Näita kodeerimiste nimekirja',
    'rapport': 'Raport',
    'show-ukood-list': 'Näita ukood {0} kodeerimisi',
    'show-lkood-list': 'Näita lkood {0} kodeerimisi',
    'download-ukood-rapport': 'Lae alla ukood {0} raport',
    'download-lkood-rapport': 'Lae alla lkood {0} raport',
    'unknown-code': 'Tundmatu kood "{0}"',
  },
  'release': {
    'released': 'Väljastatud',
    'files': 'Failid',
    'people': 'Inimesed',
    'samples': 'Proovid',
  },
  'filter': {
    'btn-filter': 'Filtreeri',
    'btn-filter-active': 'Filtreeri ({found}/{total})',
    'btn-close': 'Sulge',
    'btn-clear': 'Eemalda filter',
  },
  'multiselect': {
    'selectLabel': 'Vajuta valimiseks',
    'selectedLabel': 'Valitud',
    'deselectLabel': 'Vajuta eemaldamiseks',
  },
  'events': {
    'history': 'Ajalugu',
    'type': 'Tegevus',
    'created-at': 'Kuupäev',
    'created-by': 'Tegija',
  },
  'error': {
    1: 'Logging in error:',
    2: 'Needed to be logged in:',
    3: 'Required value missing:',
    4: 'Auth missing active session:',
    5: 'System problem:',
    6: 'Invalid value:',
    7: 'Invalid permission:',
    8: 'Ühendus probleemid:',
  },
  'fieldMessage': {
    'NO_GENS': 'Koodide genereerimine ebaennestus.\n Veendu, et valitud oleks õige tulp ja kooditüüp.',
    'REQUIRED': 'Kohustuslik väli',
    'POS_INT': 'Peab olema positiivne täisarv',
    'POS_INT_NUM': 'Peab olema positiivne täisarv üle {0}',
    'TYPE_STRING': 'Peab olema tekst',
    'TYPE_EMAIL': 'Ebasobiv email',
    'TYPE_ARRAY': 'Peab olema nimekiri',
    'TYPE_NUM': 'Peab olema number - "{0}"',
    'TYPE_PROJECT_CODE': 'Peab olema (suurtäht)(number)(number)',
    'TEXT_LENGTH': 'Lubatud pikkus {0} kuni {1} tähemärki',
    'ID_CODE_LONG': 'ID kood liiga pikk - peab olema 11 numbrit',
    'ID_CODE_SHORT': 'ID kood liiga lühike - peab olema 11 numbrit',
    'ID_CODE_CHECKSUM': 'ID kontrollsumma ei vasta - vaata üle numbrite järjekord',
    'UNKNOWN_TYPE': 'Tundmatu tüüp - "{0}"',
    'UNKNOWN_STATE': 'Tundmatu staatus - "{0}"',
    'UNKNOWN_PERMISSION': 'Tundmatu õigus - "{0}"',
    'NOT_IN_USAGE': 'Pole kasutusel',
    'IN_USAGE': 'Juba kasutusel - peavad olema unikaalsed',
    'DB_ERROR': 'Andmebaasi viga',
    'NO_PERMISSION': 'Õigused puuduvad',
    'sid': {
      'USER_REFUSED': 'Vajutasite "Katkesta" nuppu, mis katkestas teie autentimise.',
      'TIMEOUT': 'Teie sessioon on aegunud. Palun proovige uuesti.',
      'DOCUMENT_UNUSABLE': 'Palun kontrollige oma dokumendi kehtivust SmartID app\'ist.',
      'WRONG_VC': 'Valisite vale koodi. Palun proovige uuesti.',
      'sys_error': 'EstEID süsteemi viga.',
      'not_implemented': 'EstEID viga.',
    },
    'mid': {
      'TIMEOUT': 'Teie sessioon on aegunud. Palun proovige uuesti.',
      'NOT_MID_CLIENT': 'Muudub aktiivne sertifikaat.',
      'USER_CANCELLED': 'Vajutasite "Katkesta" nuppu, mis katkestas teie autentimise.',
      'DOCUMENT_UNUSABLE': 'Palun kontrollige oma dokumendi kehtivust SmartID app\'ist.',
      'SIGNATURE_HASH_MISMATCH': 'Telefoni ja Mobile-ID teenuse pakkuja vaheline tõrge.', // Mobile-ID configuration on user's SIM card differs from what is configured on service provider's side.
      'PHONE_ABSENT': 'Mobile-ID teenuse pakkuja ei saanud teie telefoniga ühendust.',
      'DELIVERY_ERROR':'Mobile-ID teenuse pakkujal ei õnnestunud SMS\'i saatmine.',
      'SIM_ERROR': 'Mobile-ID teenuse pakkuja sai Teie telefonilt ootamatu vastuse.',
      'sys_error': 'EstEID süsteemi viga.',
      'not_implemented': 'EstEID viga.',
    },
  },
  'successMessage': {
    'userUpdated': 'Kasutaja uuendatud',
    'userCreated': 'Kasutaja loodud',
    'PROJECT_CREATED': 'Projekt loodud',
    'ATTACHED_TO_PROJECT': 'Projektile lisatud',
  },
};
