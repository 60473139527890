<template>
  <div>
    <a v-bind:href='downloadUrl'>
      <button
        type="button"
        class="btn btn-primary btn-block"
        v-on:click=""
        :disabled='isCorrupt'
      >
        <span v-if="isCorrupt">{{file.storageState}} -</span>
        {{ $t('file.download') }}
        <font-awesome-icon
          class="click"
          icon="download"
          v-bind:title="$t('file.download')"
        ></font-awesome-icon>
      </button>
    </a>
  </div>
</template>

<script>
  export default {
    name: "FileDownload",
    props: {
      file: {
        type: Object,
        default: {},
      },
      downloadUrl: {
        type: String,
        default: null,
      }
    },
    computed: {
      isCorrupt() {
        return this.file.storageState === "CORRUPTED";
      }
    }
  }
</script>

<style scoped>

</style>
