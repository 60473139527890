<template>
  <div class="badge" v-bind:class="stateColor" v-text="storageState"></div>
</template>

<script>
  export default {
    name: "FileStorageState",
    props: {
      storageState: {
        type: String,
        default: "",
      }
    },
    computed: {
      stateColor() {
        switch (this.storageState) {
          case 'UPLOADED':
            return "badge-info";
          case 'CORRUPTED':
            return 'badge-danger';
          case 'CHECKED':
            return "badge-success";
        }
        return "badge-primary";
      }
    }
  }
</script>

<style scoped>

</style>
