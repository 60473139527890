<template>
  <tr>
    <td v-text="$tc('file.hash', hashAlgorithm, {hashAlgorithm})"></td>
    <td class="text-monospace" style="word-break: break-all;">{{ hash }}</td>
  </tr>
</template>

<script>
export default {
  name: "TrFileHash.vue",
  props: {
    hashInfo: {
      type: String,
      default: "",
    }
  },
  computed: {
    hasHashAlgorithm() {
      return !!this.hashInfo && this.hashInfo.includes(':');
    },
    hash() {
      if (this.hasHashAlgorithm) {
        return this.hashInfo.split(':')[1];
      }
      return this.hashInfo;
    },
    hashAlgorithm() {
      if (this.hasHashAlgorithm) {
        return this.hashInfo.split(':')[0];
      }
      // hack for i18n for it to work with first element without extra data
      return 1;
    },
  }
}
</script>

<style scoped>

</style>
