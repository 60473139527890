<template>
  <div class="container-fluid">
    <transition name="appear-h">
      <section v-if="isInfoVisible">
        <table class="table table-sm border-top-0">
          <tbody>
<!--          <tr>-->
<!--            <td v-text="$t('file.name')"></td>-->
<!--            <td v-text="file.name"></td>-->
<!--          </tr>-->
          <tr>
            <td v-text="$t('file.size')"></td>
            <td>{{ file.size | humanFileSize }}</td>
          </tr>
          <tr>
            <td v-text="$t('file.lines')"></td>
            <td v-text="file.lines"></td>
          </tr>
          <!--        <tr v-if="file.people">-->
          <!--          <td v-text="$t('file.people')"></td>-->
          <!--          <td v-text="file.people"></td>-->
          <!--        </tr>-->
          </tbody>
        </table>
      </section>
    </transition>
    <file-preview v-bind:file="file" v-if="isPreviewVisible"></file-preview>
    <slot v-if="isInfoVisible"></slot>
  </div>
</template>

<script>
export default {
  name: "FileInfo",
  props: {
    file: {
      type: Object,
      default: [],
    },
    isInfoVisible: {
      type: Boolean,
      default: false,
    },
    isPreviewVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
